<template>
  <div>
    <div class="tabs">
      <el-row>
        <el-col :span="3">
          <span class="title">院校性质></span>
        </el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.nature_id === null }" @click="choose('nature_id', null, 0)">全部</span>
          <span :class="{ item: true, act: check.nature_id === 1 }" @click="choose('nature_id', 1, 0)">公办</span>
          <span :class="{ item: true, act: check.nature_id === 2 }" @click="choose('nature_id', 2, 0)">民办</span>
          <span :class="{ item: true, act: check.nature_id === 3 }" @click="choose('nature_id', 3, 0)">中外合作办学</span>
          <span :class="{ item: true, act: check.nature_id === 4 }"
            @click="choose('nature_id', 4, 0)">内地与港澳台地区合作办学</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><span class="title">所属地区></span></el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.city_id.length === 0 }" @click="choose('city_id', -1, 1)">全部</span>
          <span v-for="(item, i) in cityList" :key="i" :class="{ act: check.city_id.indexOf(item.city_code) > -1 }"
            @click="choose('city_id', item.city_code, 1)">{{ item.city }}</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="3"><span class="title">招生单位></span></el-col>
        <el-col :span="21">
          <span v-for="(item, i) in recruitUnitList" :key="i"
            :class="{ act: check.specific_code.indexOf(item.id) > -1 }" @click="choose('specific_code', item.id, 1)">
            {{ item.name }}
          </span>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import conditionalSearch from "@/assets/data/conditional-search";
export default {
  name: '',
  data() {
    return {
      recruitUnitList: conditionalSearch.recruitUnitList,
      cityList: conditionalSearch.city,

      check: {
        city_id: [],
        specific_code: [2, 7, 4, 5, 3],
        nature_id: null,
      },
      specific_code: 1,
    };
  },
  methods: {
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.pageNum = 1;
      this.$emit('doSearch', this.check)
    },
  },
};
</script>

<style scoped lang='less'>
.tabs {
  color: #666666;
  letter-spacing: 2px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  margin-bottom: 30px;

  .title {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    text-align: center;
  }

  span {
    display: inline-block;
    padding: 0 12px;
    line-height: 27px;
    margin-bottom: 3px;
    margin-right: 10px;

    &:hover {
      background: #2b7bf3;
      color: white;
    }
  }
}

.act {
  background-color: #2b7bf3;
  color: #ffffff;
}
</style>
