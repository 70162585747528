<template>
  <div>
    <!-- 工具栏start -->
    <div class="search-box">
      <div class="title_b">
        <div> 报考高手提醒:建议平行志愿1至平行志愿12的位置设置为：冲一冲、冲一冲、稳一稳、稳一稳、稳一稳、稳一稳、保一保、保一保、保一保、保一保、垫一垫、垫一垫。</div>
      </div>
      <conditional-search @doSearch="doSearch"></conditional-search>
      <el-row type="flex" align="middle">
        <el-col :span="8">搜索大学:
          <el-input v-model="keyword" placeholder="请输入院校名称" style="width: 300px" size="mini" clearable></el-input>
        </el-col>
        <el-col :span="13" class="searchDiv">
          <span class="old_search" v-if="oldSearch.length == 0">暂无历史记录</span>
          <span class="old_search" v-for="(item, i) in oldSearch" :key="i">
            <span @click="searchLog(item)">{{ item }}</span>
            <span @click="logDel(item)">&nbsp;x</span>
          </span>
        </el-col>
        <el-col :span="2" :offset="1">
          <el-button class="college-btn" type="primary" size="mini" round @click="getList">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <!--工具栏 end  -->
    <div>
      <college-list v-for="(item, i) in CollegeListData" :item="item" :batch="batch" :len="len" :key="i" :change="change"
        @refush=updateReport>
      </college-list>
    </div>


    <!-- 分页 -->
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="page.pageSize" layout=" prev, pager, next"
            :total="page.total">
          </el-pagination>
        </div>
      </template>
    </div>
    <tool-tip />
  </div>
</template>

<script>
import conditionalSearch from "./components/conditional-search.vue";
import collegeList from "./components/college-list.vue";
import ToolTip from "./components/ToolTip.vue";

import { mapState, mapMutations } from "vuex";
export default {
  name: "",
  components: {
    ToolTip,
    conditionalSearch,
    collegeList
  },
  computed: {
    ...mapState(["userInfo", 'batch', 'record', 'reportTable']),
  },

  data() {
    return {
      check: {
        city_id: [],
        specific_code: [2, 7, 4, 5, 3],
        nature_id: null,
      },
      keyword: "",
      oldSearch: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      CollegeListData: [],
      form: {
        score: 0,
        is_wenli: 1,
      },
      //等同分
      oldLineScore: [0, 0, 0],
      len: 12,
      change: 1,
      reportTables: []
    };
  },

  watch: {
    //批次是否变化（刷新数据）
    batch() {
      this.word = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二']
      this.setTableReportData()
      this.getList();
    },
  },

  mounted() {
    this.$emit("Nav", 1);

    //获取批次信息
    this.word = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二']
    if (this.reportTable == null) {
      this.setTableReportData()
    }
    //用户信息
    this.form.score = this.userInfo.score;
    this.form.is_wenli = this.userInfo.is_wenli;

    //获取院校列表
    this.getList();
  },
  methods: {
    ...mapMutations(['setOldLineScore', 'setEqualScore', 'setReportTable']),
    //加载默认志愿表

    //搜索条件
    doSearch(item) {
      this.page.pageNum = 1
      this.check = item
      this.getList()
    },

    //搜索历史
    searchLog(item) {
      this.keyword = item;
      this.getList();
    },
    logDel(item) {
      let index = this.oldSearch.indexOf(item);
      if (index > -1) {
        this.oldSearch.splice(index, 1);
      }
    },

    //获取院校列表
    getList() {
      if (this.batch == 0) {
        return;
      }
      //按院校名搜索
      if (this.keyword) {
        let index = this.oldSearch.indexOf(this.keyword);
        if (index > -1) {
          console.log("搜索过");
        } else {
          if (this.oldSearch.length > 2) {
            this.oldSearch.shift();
          }
          this.oldSearch.push(this.keyword);
        }
        this.$fecth
          .post("volunteer/collegeSearch", {
            pageNum: this.page.pageNum,
            pageSize: this.page.pageSize,
            is_wenli: this.form.is_wenli,
            batch: this.batch,
            keyword: this.keyword,
            city_id: this.check.city_id,
            nature_id: this.check.nature_id,
            specific_code: this.check.specific_code,
          })
          .then((res) => {
            res = this.getDengji(res);
            this.CollegeListData = res.lists;
            this.page.total = res.count;
          });
      } else {
        this.$fecth
          .post("volunteer/GetSchoolList", {
            pageNum: this.page.pageNum,
            pageSize: this.page.pageSize,
            score: this.form.score,
            city_id: this.check.city_id,
            is_wenli: this.form.is_wenli,
            batch: this.batch,
            nature_id: this.check.nature_id,
            specific_code: this.check.specific_code,
            keyword: this.keyword,
          })
          .then((res) => {
            res = this.getDengji(res);
            this.CollegeListData = res.lists;
            this.page.total = res.count;
          });
      }
    },

    getDengji(res) {
      res.lists.forEach((item) => {
        let key = 2.5
        if (this.batch == 3) {
          key = 4
        }
        let num = Number(this.form.score) + 5 - Number(item.entry_score);
        item.dengji = Math.ceil(num / key);
      });
      return res;
    },

    //翻页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    updateReport() {
      this.change += 1
    },

    setTableReportData() {
      this.reportTables = [];
      for (let i = 0; i < this.len; i++) {
        this.reportTables.push({
          name: "暂无",
          specialty: [],
        });
      }
      this.setReportTable(this.reportTables)
    }
  },
};
</script>

<style scoped lang='less'>
.search-box {
  margin: 20px 0;
}

.title_b {
  text-align: center;
  background: #c5e5fe;
  line-height: 40px;
  position: relative;
  top: -15px;
  color: #3d3d3c;
  border-radius: 7px;
}

.old_search {
  border: 1px solid #f3f3f3;
  background: #f3f3f3;
  padding: 3px 7px;
  border-radius: 10px;
  font-size: 12px;
  margin-right: 10px;
}
</style>
