<template>
  <div>

    <table class="tb1" border="1px">
      <v-head />
      <tbody v-if="!item.new_college">
        <tr>
          <td rowspan="3" style="color:gray" v-if="!word[item.dengji - 1]">无法推荐 </td>
          <td rowspan="3" v-else>平行志愿{{ word[item.dengji - 1] }}</td>
          <td rowspan="3" class="school_data">
            <p>{{ item.name }}【{{ item.school_id }}】<span v-if="item.degree != null && batch == 3">*</span> </p>
            <p>办学性质:{{ item.nature }} 所在地:{{ item.city_name }}</p>
            <p>录取规则:{{ item.lqgz }}</p>
            <p v-if="item.cautious">近年数据浮动，谨慎选择填报 </p>
            <p style="color: red;" v-if="item.dengji > 10 && item.plan_num < 50">谨慎填报 </p>
            <p style="color: red;" v-if="item.dengji > 10 && item.name.indexOf('（') > -1 && item.plan_num > 50">谨慎填报 </p>
          </td>
          <td rowspan="3">{{ item.plan_num }}</td>
          <!--                    年份-->
          <td>{{ item.year_1 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_1 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_1 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_1 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_1 }}</td>
          <!--  平均分线差-->
          <td>{{ (item.mean_score_1 - oldLineScore["0"].score) | Zero }}</td>
          <!--  最低分-->
          <td>{{ item.td_min_score_1 ? item.td_min_score_1 : item.min_score_1 }}</td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_1 }}</td>

          <!--  最低分线差-->
          <td style="color: red">
            {{ item.td_min_score_1 ? item.td_min_score_1 - oldLineScore["0"].score : item.min_score_1 -
              oldLineScore["0"].score | Zero }}
          </td>
          <td rowspan="3">
            <el-popover v-model="item.visible" popper-class="popover-f" placement="bottom" trigger="manual">
              <div class="popover-box">
                <p class="box-title">填报为</p>
                <p v-for="h in len" :key="h" class="box-item" @click="chooseCollege(item, h, word[h - 1])">
                  平行志愿{{ word[h - 1] }}
                </p>
              </div>
              <div v-if="record.id.indexOf(item.select_code) > -1" slot="reference" class="btn schoolBtn"
                @click="changeAlert(item)">
                志愿{{ record.dg[record.id.indexOf(item.select_code)] }}
              </div>
              <div v-else slot="reference" class="btn" @click="changeAlert(item)">填报为</div>
            </el-popover>

            <div class="text-c color-7">
              <div class="btn" @click="getSpecialty(item)">
                查看专业
              </div>
            </div>
          </td>
        </tr>
        <tr class="bg_line">
          <td>{{ item.year_1 - 1 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_2 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_2 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_2 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_2 }}</td>
          <!--  平均分线差-->
          <td>{{ (item.mean_score_2 - oldLineScore["1"].score) | Zero }}</td>
          <!--  最低分-->
          <td>{{ item.td_min_score_2 ? item.td_min_score_2 : item.min_score_2 }}</td>
          <!--  最低分位次-->
          <td style="color: red"> {{ item.ranking_2 }}</td>
          <!--  最低分线差-->
          <td style="color: red"> {{ item.td_min_score_2 ? item.td_min_score_2 - oldLineScore["1"].score :
            item.min_score_2 -
            oldLineScore["1"].score | Zero }}</td>
        </tr>
        <tr>
          <td>{{ item.year_1 - 2 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_3 }} </td>
          <!--  录取数-->
          <td>{{ item.matriculate_3 }} </td>
          <!--  最高分-->
          <td>{{ item.max_score_3 }} </td>
          <!--  平均分-->
          <td>{{ item.mean_score_3 }}</td>
          <!--  平均分线差-->
          <td>{{ (item.mean_score_3 - oldLineScore["2"].score) | Zero }}
          </td>
          <!--  最低分-->
          <td>{{ item.td_min_score_3 ? item.td_min_score_3 : item.min_score_3 }}</td>
          <!--  最低分位次-->
          <td style="color: red"> {{ item.ranking_3 }} </td>
          <!--  最低分线差-->
          <td style="color: red"> {{ item.td_min_score_3 ? item.td_min_score_3 - oldLineScore["2"].score :
            item.min_score_3 -
            oldLineScore["2"].score | Zero }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td rowspan="3" style="color:gray" v-if="!word[item.dengji - 1]">无法推荐 </td>
          <td rowspan="3" v-else>平行志愿{{ word[item.dengji - 1] }}</td>
          <td rowspan="3" class="school_data">
            <p>{{ item.name }}【{{ item.school_id }}】<span v-if="item.degree != null && batch == 3">*</span> </p>
            <p>办学性质:{{ item.nature }} 所在地:{{ item.city_name }}</p>
            <p>录取规则:{{ item.lqgz }}</p>
          </td>
          <td rowspan="3">{{ item.plan_num }}</td>
          <!--                    年份-->
          <td colspan="9">新增招生单位，谨慎选择填报</td>
          <!--计划数 -->
          <td rowspan="3">
            <el-popover v-model="item.visible" popper-class="popover-f" placement="bottom" trigger="manual">
              <div class="popover-box">
                <p class="box-title">填报为</p>
                <p v-for="h in len" :key="h" class="box-item" @click="chooseCollege(item, h, word[h - 1])">
                  平行志愿{{ word[h - 1] }}
                </p>
              </div>
              <div v-if="record.id.indexOf(item.select_code) > -1" slot="reference" class="btn schoolBtn"
                @click="changeAlert(item)">
                志愿{{ record.dg[record.id.indexOf(item.select_code)] }}
              </div>
              <div v-else slot="reference" class="btn" @click="changeAlert(item)">填报为</div>
            </el-popover>

            <div class="text-c color-7">
              <div class="btn" @click="getSpecialty(item)">
                查看专业
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- 弹出框-选择专业 -->
    <el-dialog :visible.sync="dialogVisible" class="mojarDialog" width="1200px" @change="RefreshTable">
      <mojar-item :collegeData="collegeData" v-if="dialogVisible" :len="len" @Refresh="RefreshTable" @close="closeDiaLog"
        :keyword="keyword" />
    </el-dialog>
  </div>
</template>

<script>
import vHead from './VHead'
import mojarItem from './mojar-item/index'
import { mapState, mapMutations } from "vuex";
export default {
  name: '',
  props: ['item', 'batch', 'len', 'change', 'keyword'],
  components: {
    vHead, mojarItem
  },
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      collegeData: [],
      dialogVisible: false
    };
  },
  watch: {
    change() {
      this.$forceUpdate();
    }
  },

  computed: {
    ...mapState(["oldLineScore", 'record', 'reportTable']),
  },
  filters: {
    Zero(e) {
      if (e > -100) {
        return e;
      } else {
        return "-";
      }
    },
  },


  methods: {
    ...mapMutations(['setRecord', 'setReportTable']),
    changeAlert(item) {
      item.tiaoji = 1;
      item.visible = !item.visible;
      this.$forceUpdate();
    },

    chooseCollege(item, i, num) {
      //选择院校
      item.visible = false;
      item.tiaoji = 1;
      //判断是否选择过此学校。如果有就删掉
      let ttt = this.record.id.indexOf(item.select_code);
      if (ttt > -1) {
        this.record.dg.splice(ttt, 1);
        this.record.id.splice(ttt, 1);
        this.record.sp.splice(ttt, 1);
      }
      //判断此位置是否填报过。如果有。覆盖
      let index = this.record.dg.indexOf(num);
      if (index > -1) {
        this.record.id[index] = item.select_code;
        this.record.dg[index] = num;
        this.record.sp[index] = [];
      } else {
        //没有新增
        this.record.id.push(item.select_code);
        this.record.dg.push(num);
        this.record.sp.push([]);
      }

      this.setRecord(this.record)
      this.$forceUpdate()


      //判断此院校是否被其他位置已经填报。有就删除
      for (let s of this.reportTable) {
        if (s.select_code === item.select_code) {
          s.name = "暂无";
          s.specialty = [];
          delete s.school_id;
          delete s.city_name;
          delete s.plan_num;
          delete s.min_score;
          delete s.ranking;
          delete s.select_code;
          delete s.score;
          delete s.lqgz;
        }
      }
      this.reportTable[i - 1].name = item.name;
      this.reportTable[i - 1].school_id = item.school_id;
      this.reportTable[i - 1].city_name = item.city_name;
      this.reportTable[i - 1].plan_num = item.plan_num;
      this.reportTable[i - 1].select_code = item.select_code;
      this.reportTable[i - 1].score = item.min_score;
      this.reportTable[i - 1].ranking = item.ranking;

      this.reportTable[i - 1].lqgz = item.lqgz;
      this.reportTable[i - 1].tiaoji = 1;
      this.reportTable[i - 1].specialty = [];
      this.setReportTable(this.reportTable)
      this.RefreshTable()
    },
    RefreshTable() {
      this.$emit('refush')
      setTimeout(() => {
        this.dialogVisible = false
      }, 500)

    },
    //查看专业
    getSpecialty(item) {
      this.collegeData = item;
      this.dialogVisible = true;
    },
    closeDiaLog() {

    }
  },
};
</script>

<style scoped lang='less'>
.tb1 {
  width: 100%;
  height: 180px;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;

  tbody {
    line-height: 28px;

    .bg_line {
      background: #f4f7fc;
    }
  }
}

// 按钮
.btn {
  border-radius: 20px;
  border: 1px solid #409eff;
  color: #8c939d;
  width: 68px;
  margin: 3px auto 3px;
  font-size: 12px;
  line-height: 24px;
}

.schoolBtn {
  border-radius: 4px;
  background: #409eff;
  color: white;
}


//选择志愿框
.box-title {
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}

.box-item {
  padding: 10px 0;
  font-size: 14px;
}
</style>
